import React, { useState, useEffect } from 'react';
import Socials from './Socials';
import headshot from '../Assets/headshot.jpg';
import { Link } from 'react-router-dom'

export default function Header() {

    const [header, setHeader] = useState();

    useEffect(()=> {
        let random = Math.floor(Math.random() * 6)+1
        setHeader(`/Assets/backdrop0${random}.webp`);
    },[])

    return (
        <div className='container-fluid'>
            <div className='row text-center row-header' style={{backgroundImage: `url(${header})`}}>
                <div className="col-xs-12">
                        <div className='header-col'>
                            <Link className="link" to="/">
                                <img className='img-responsive profile-pic center-block' src={headshot} alt='profile-pic'/>
                                <h1 className='name'>James Balcombe</h1>
                                <h2 className='bio'>A Fullstack Software Engineer with a preference for backend.</h2>
                            </Link>
                            <Socials />
                        </div>
                </div>
            </div>
        </div>
    );
}
