import { Routes, Route } from "react-router-dom";
import Header from './components/Header'
import Projects from './components/Projects'
import About from './components/About'
import './App.css';
import Tutoring from './components/Tutoring';

export default function App() {
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/" element={<><About /><Projects /> </>} />
        <Route path="/tutoring" element={<Tutoring />} />
      </Routes>
      <p className='aboutSite'>Copyright © 2021 James Balcombe - built with React.js</p>
    </div>
  );
}