import React from 'react';
import twitter from '../Assets/icons/twitter.png'
import linkedin from '../Assets/icons/linkedin.png'
import github from '../Assets/icons/github.png'

export default function Socials() {

    return (
        <div className='social-links'>
            <a href="https://twitter.com/CodeWithMrB" target="_blank" rel="noreferrer"><img src={twitter} alt="twitter"/></a>
            <a href="https://www.linkedin.com/in/james-balcombe1983/" target="_blank" rel="noreferrer"><img src={linkedin} alt="linkedin"/></a>
            <a href="https://github.com/jamesbalcombe83" target="_blank" rel="noreferrer"><img src={github} alt="github"/></a>
        </div>
    );
}