import React from 'react';

export default function About() {

    return (
        <div className='about'>
            <h2 className=''>About Me</h2>
            <h3 className='bio'>I'm a Computer System Engineering graduate, who previously taught Computer Science in the UK, China, and Japan for 12 years. After tinkering with a few personal projects, and wanting new challenges professionally, I enrolled in a Fullstack Engineering course, and now work as a Software Engineer for an international company in Tokyo.</h3>
            <h3 className='bio'>I currently live in Tokyo with my wife and daughters.</h3>
        </div>
    )
}