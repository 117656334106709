import React from 'react';


export default function Tutoring() {

    return (
        <div className='about'>
            <h1 className='bio'>Computer Science Tutoring</h1>
            <h3 className='bio'>I offer bespoke Computer Science and programming tutoring to secondary (middle/high school) age children.</h3>
            <h2 className='bio'>My Qualifications</h2>
            <ul>
                <li><h3 className='bio'>PGCE in Secondary Education from the Institute of Education, London</h3></li>
                <li><h3 className='bio'>Degree in Computer Systems Engineering, University of Warwick (BEng)</h3></li>
                <li><h3 className='bio'>12 years of teaching experience in three countries</h3></li>
                <li><h3 className='bio'>Delivery of GCSE, IGCSE, A-Levels and IB Diploma courses</h3></li>
                <li><h3 className='bio'>Former students achieving Best in China and Best in World scores</h3></li>
                <li><h3 className='bio'>Former students attending Ivy League and Russell Group universities</h3></li>
                <li><h3 className='bio'>Former students working at Amazon, Google, etc.</h3></li>
                <li><h3 className='bio'>Language experience: Python, C#, Java, JavaScript, Go</h3></li>
            </ul>
            <h2 className='bio'>What I offer</h2>
            <h3 className='bio'>One on one bespoke sessions to develop, guide, and support your son or daughter in any area of Computer Science or programming. Either as preparation for specific examinations and syllabi or general Computer Science knowledge, such as data structures and representation, algorithms, computational thinking, networking, and more. Programming ideas and concepts can be taught language agnostic, or in specific languages as needed.</h3>
            <h3 className='bio'>After a free initial consultation with you and your child, we will establish a program of study that fits their needs and sets  expectations for both parties.</h3>
            <h3 className='bio'>All lessons will take place on Google Meet utilising a wide range of resources and learning tools, as well as following strict protocols for the online safety of your child and me.</h3>
            <h2 className='bio'>Contact</h2>
            <h3 className='bio'>If you are interested please contact me <a href="mailto:tutoring@jamesbalcombe.com"> here</a></h3>
        </div>
    );
}