import React from 'react';

export default function TechIcons({ techs }) {

    const icons = {
        'axios': 'axios.png',
        'react': 'react.svg',
        'javascript': 'javascript.svg',
        'knex': 'knex.svg',
        'express': 'node.svg',
        'node': 'node.svg',
        'postgres': 'postgres.svg',
        'rails': 'rails.svg',
        'recoil': 'recoil.jpg',
        'react-native': 'react.svg',
        'puppeteer': 'puppeteer.svg',
        "heroku": 'heroku.svg',
        'chartjs': 'chartjs.svg',
        'mongodb': 'mongodb.svg',
        'apify': 'apify.svg',
        'go': 'go.svg'
    }

    return (
        <div className="icons">
            {techs.map((tech, index) => {
                return <img src={`/Assets/icons/${icons[tech]}`} alt={tech} className="techIcon" key={index} />
            })}
        </div>
    )
}