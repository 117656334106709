import React from 'react';
import TechIcons from './TechIcons';

export default function Project_Card({ data }) {

    return (
        <div className="projectCard">
            <div className='imageContainer'>
                <img src={data.imageURL} className="projectImage" alt={data.name} />
            </div>
            <div className="projectDetails">
                <h5 className="">{data.name}</h5>
                <h6 className="">{data.description}</h6>
                <TechIcons techs={data.technologies} />
                <div className="links">
                    {data.site ? <a href={data.site[1]} target='_blank' rel="noopener" className="btn btn-secondary shadow">{data.site[0]}</a> : null}
                    {data.gitrepo ? <a href={data.gitrepo} target='_blank' rel="noopener" className="btn btn-secondary shadow">View the code</a> : null}
                </div>
            </div>
        </div>
    );
}