import React, { useState, useEffect } from 'react';
import Project_Card from './Project_Card';
import projectData from '../Assets/projects/project-data.json';

export default function Projects() {

    const [projects, setProjects] = useState();

    useEffect (() => {
        setProjects(projectData);
    }, [])

    return (
        <div className='projects'>
            <h2 className=''>Projects</h2>
            {!projects ? null : projects.map((data,index) => <Project_Card key={index} data={data} />)}
        </div>
    );
}